<template>
  <v-container id="view-root">
    <v-layout justify-center  >
      <div class="columna" style="margin-top:200px">
        <div class="conflex">
          <span :class="$cfg.app.bar.titulo_class">
            {{ $cfg.app.bar.titulo }}
            <span :class="$cfg.app.bar.subtitulo_class">{{
              $cfg.app.bar.subtitulo
            }}</span>
          </span>
        </div>

        <span>{{ `Versión: ${$store.state.G.VERSION}` }}</span>
      </div>
    <div style="position:fixed; bottom:10px; left:10px">
         ABAKAN sistemas
    </div>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    cfg: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      logo: require("@/assets/img/logo.png")
    };
  }
};
</script>

<style scope>
#view-root .logo {
  opacity: 0.1;
}
</style>
